.hIThd{
    margin: 0;
    padding: 0;
    line-height: normal;
    font-size: 27px;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    color: #2e353e;
    letter-spacing: -.05em;
}

.underline{
    border-bottom: 2px solid #e15148;
}
/* .CCIMG1{
    width: 100% !important;
    margin-top: -30em;
}
.CCIMG2{
    width: 95% !important;
    margin-top: 3.5em;
} */
.HITICONS{
    color: #e15148;
    font-size: 1em;
    width: 4em;
    height: 4em;
    margin-bottom: 1em;
}
.grp{
    width: 15em;
    margin-top: 2em;
    text-align: center;
}
.grp > p{
    font-size: 14px;
    font-weight: 400;
    color: #2e353e;
    text-align: center;
}