.footerp {
  font-size: 11.34px;
}
.footIcon {
  font-size: 25px;
  margin: 0 .2em;
  font-weight: 100;
  color: #e15148;
}
.footericn {
  margin-top: 0.34em;
}
.ftext {
  color: #000 !important;
}
.ftext:hover {
  color: #e15148 !important;
}