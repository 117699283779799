.pri-container {
  display: flex;
}
.pricon {
  font-size: 25px;
}
.privacy > header {
  margin-top: 2em;
  padding-bottom: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}
.privacy > header > h1 {
  margin: auto;
  color: #e15148;
  font-size: 27px;
  font-weight: 600;
}
