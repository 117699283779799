.Newshd {
  font-size: 16px;
}
.newbd {
  font-weight: 800 !important;
}
.Newsp {
  font-size: 13px;
}
input.subForm {
  width: 25em;
  height: 3em;
  padding-left: 2em;
  border-radius: 1em 0em 0em 1em;
}
Button.subBTN {
  padding: 1em 2em;
  height: 4em;
  border: none;
  border-radius: 0em 1em 1em 0em;
  background-color: #e15148;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  opacity: 0.9;
  transition: 0.5s ease;
}
input.subForm::placeholder {
  font-size: 12px;
}
Button.subBTN:hover {
  opacity: 1;
  background-color: #e15148;
}

@media (max-width: 375px) {
  input.subForm {
  width: 12em;
  padding-left: 1em;
  }
  
Button.subBTN {
    padding: 1em 1.5em;
}
}
