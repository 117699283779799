.proimg > img{
    width: 100%;
    height: 100%;
}
.layhd{
    font-size: 27px;
    font-weight: 600;
    color: #2e353e;
}
.layp{
    font-size: 14px;
    font-weight: 400;
    color: #777777;
}
.layoutBtn{
    border-radius: 3em;
    border: 1px solid #e15148;
    background-color: #fff; 
    color: #777777;
    font-weight: 400;
    font-size: 12px;
    font-weight: 600;
    padding: .8em 2em;
}