.faqshd{
    font-size: 27px;
    font-weight: 600;
    padding: 0 !important;
    color: #2e353e;
    line-height: 1.5em;
}
.faqsp{
    font-size: 14px;
    color: #777777;
}
.accordion-button{
    font-size: 13.5px !important;
    color: #F4645A !important;
    font-family: Open Sans, sans-serif !important;
    font-weight: 600 !important;
    border-left: 2px solid #F4645A !important;
}
.accordion-body{
    border: none !important;
    font-family: Open Sans, sans-serif !important;
    font-size: 14px !important;
    color: #2E353E !important;
}