.fethd{
    font-family: Poppins, sans-serif;
    font-weight: 600;
    color: #2e353e;
    font-size: 27px;
}
.feattxt{
    font-size: 13px;
    color: #777;
}
.featIcons{
    font-size: 1em;
    color: #e15148 !important;
    border: 1px solid #777;
    width: 3.2em;
    height: 3em;
    padding: .6em;
    border-radius: 50%;
}
.keytxt{
    width: 90%; 
}

@media (max-width : 756px) {
    .rtl{
        direction: rtl;
    }
    .texxt{
    text-align: right;
    }
}   