.appdownload{
    background-color: #242b32!important;
    padding: 1em 0;
}

.dwnbtn{
    background-color: #e15148;
    color: #fff;
    border: none;
    border-radius: 2em;
    padding: 1em 1.5em;
}
.onLine{
    transition: .8S ease;
    padding-bottom: 2em;
}
.onLine:hover{
    color: #e15148 !important;
    border-bottom: 1px solid #fff;
}